.container {
    background: #eff3f9;
    min-height: 100vh;
    overflow: hidden;
    width: 100%;
    transition: 300ms;
    box-sizing: border-box;
}

.container > .details {
    background-image: linear-gradient(135deg, #0061f2 0%, rgba(105, 0, 199, 0.8) 100%);
    padding: 30px 140px 30px 140px;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(31, 45, 65, 0.15);
}

.container > .details > h1,
.container > .details > h3,
.container > .details > span > h1,
.container > .details > span > h3 {
    color: #FFF;
}

.container > .details {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.container > .details > span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.container > .details > span > img {
    width: 210px;
}

.container > .details > span {
    width: 33%;
}


.details > span > span.buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 950px;
    max-width: 95%;
    margin: auto;
}

.details > span > span.buttons > button {
    padding: 8px 15px;
    color: #004E8C;
    border: 3px solid #004E8C;
    background: #FFF;
    border-radius: .42rem;
    min-width: 100px;
    font-size: 1em;
    font-weight: bold;
    cursor: pointer;
    margin: 15px;
}

.loading {
    text-align: center;
    margin: 20px auto;
    color: #333;
}

.form {
    margin: 40px auto;
    margin-bottom: 400px !important;
}

.selected { text-decoration: underline !important; }

@media only screen and (max-width : 1080px) {
    .container > .details {
        padding: 30px 40px 30px 40px;
        flex-direction: column;
    }

    .container > .details > span {
        width: 100%;
        margin-bottom: 20px;
    }
}