.input.alone {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    max-width: 80%;
    margin: 0px auto;
}

.input.alone > p {
    font-size: 0.85em;
    color: red;
    margin-left: 5px;
    margin-top: 2.5px;
    margin-bottom: 0px;
}

.input.alone > p.waring {
    color: #C9ac00 !important;
    font-weight: bold;
}

.input.alone > label {
    margin-bottom: 8px;
    color: #777;
    font-weight: bold;
    font-size: 0.9em;
}

.input.alone > input::placeholder, .input.alone > textarea::placeholder { color : #a3a6ac} 

.input.alone > textarea {
    min-width: calc(100% - 36px);
    max-width: calc(100% - 36px);
    min-height: 80px;
    max-height: 100px;
}

.input.alone > input,
.input.alone > textarea {
    border: 2px solid #a3a6ac;
    border-radius: 5px !important;
    overflow: hidden;
    padding: 4px 8px !important;
    background-color: transparent;
    color: #a3a6ac;
}

.input.alone > textarea {
    font-family: Tahoma, "Quicksand", sans-serif;
}


.input.alone > .overloaded {
    display: flex;
}

.input.alone > .overloaded > a {
    color: #0061f2;
    text-decoration: none;
    margin-right: 10px;
    text-decoration: underline;
    flex-wrap: wrap;
}

.input.alone > input:focus,
.input.alone > textarea:focus {
    border-color:#FFF !important;
    outline: none;
}
